.logo {
  height: 43px;
  padding: 20px 10px;
}

.consent-titles, .consent-texts {
  padding: 0px 10px;
}

.consent-title {
  line-height: 1.5em;
  font-size: 24px;
  display: block;
  font-weight: 600;
  font-style: normal;
  font-family: 'Open Sans', sans-serif;
}

.consent-text, .consent-info, .legal-text {
  font-size: 13px;
  line-height: 1.5em;
  font-style: normal;
  font-family: 'Open Sans', sans-serif;
}

.consent-text {
  margin-top: 5px;
  -webkit-print-color-adjust: exact;
  background: #EBEFEB;
}

.consent-info {
  margin-top: 10px;
}

.consent-text b {
  font-weight: 600;
}

.legal-text li {
  padding: 10px 10px 0px 0px;
  list-style-type: disc;
  margin-left: 20px;
}

.more {
  font-weight: 600;
}
